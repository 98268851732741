.header-background {
  background-color: var(--bs-gray-700);
}

header {
  position: relative;
}

header .logo {
  font-size: 1.5rem;
}

.header-explain {
  position: absolute;
  right: 0;
  font-size: 0.75rem;
}
