body {
  font-family: 'Lato', sans-serif;
  background-color: var(--bs-gray-900);
  --correct: rgb(83, 141, 78);
  --wrong-place: rgb(181, 159, 59);
  --wrong-letter: rgb(58, 58, 60);
  --wrong-red: rgb(148, 55, 55);
}

a {
  cursor: pointer;
}

.color-correct {
  color: var(--correct);
}

.color-wrong-place {
  color: var(--wrong-place);
}

.color-wrong-letter {
  color: var(--wrong-letter);
}

.color-200 {
  color: var(--bs-gray-200);
}

.color-500 {
  color: var(--bs-gray-500);
}

.fill-correct {
  fill: var(--correct);
}

.fill-wrong-place {
  fill: var(--wrong-place);
}

.fill-wrong-letter {
  fill: var(--wrong-letter);
}

.fill-stats-background {
  fill: var(--bs-gray-900);
}

.fill-wrong {
  fill: var(--wrong-red);
}

.avg-line {
  stroke-width: 2;
  stroke: #FFFFFF;
}

.logo {
  font-weight: bold;
}

.logo :nth-child(2) {
  font-family: Consolas, monospace;
}

.wrapper {
  max-width: 768px;
}